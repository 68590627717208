import { Injectable } from '@angular/core';
import  { AccountService } from '@services/account.service';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivacypolicyResolver implements Resolve<any> {
  constructor(
    private accountsService: AccountService,
  )
  { }
  resolve(){
    //get the terms
    return new Promise((resolve, reject) => {
      this.accountsService.getPrivacyPolicy().subscribe((terms:any)=> {
        console.log(terms);
           if(terms){
             resolve(terms[0].descrption)
           } else {
            resolve("Loading terms failed");
            console.log("No terms found");
           }
        });                
   });
  }
}
