import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import {Apollo, gql, QueryRef} from 'apollo-angular';
import{ Router } from '@angular/router';
import { tap } from 'rxjs/operators';
export const TOKEN_KEY = 'healthuno-marketplace-token';
export const USER_KEY = 'healthuno-marketplace-user';

import { RegisterUsergql,SendOtpgql,VerifyOtpgql,LoginUsergql,ForgotPasswordgql }  from './graphql.service';
import  { ProductService } from '@services/product.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  authState = new BehaviorSubject(false);
  Authenticated: BehaviorSubject<boolean> = new BehaviorSubject(null);
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  userdata: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  getlocation:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data = this.getlocation.asObservable();
  public setData(data: string){
    this.getlocation.next(data);
  }
  referralcontent:QueryRef<any>;
  referral:QueryRef<any>;
  res_partner_data:QueryRef<any>;
  constructor(private apollo: Apollo,private RegisterUsergql: RegisterUsergql,private SendOtpgql: SendOtpgql,
    private VerifyOtpgql: VerifyOtpgql,private loginUsergql: LoginUsergql,private productService: ProductService,
    private router: Router,private forgotpasswordgql:ForgotPasswordgql) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Preferences.get({ key: TOKEN_KEY });
    const user = await Preferences.get({ key: USER_KEY });
    if (token && token.value) {
      // console.log('set token: ', token.value);
     // this.token = token.value;
      this.token.next(token.value);
      this.userdata.next(JSON.parse(user.value));
      this.isAuthenticated.next(true);
    } else {
      this.token.next(null);
      this.isAuthenticated.next(false);
      this.userdata.next(null);
    }
  }

  otplogin(data,sms_code) {
    return this.apollo.mutate({
      mutation: this.SendOtpgql.document,
      variables: {
        'phone_number': String(data.phone_number),
        'sms_code': String(sms_code),
      }
    });
  }

  verifyotp(otpdata){
    console.log('login data',otpdata)
    const otpverify = gql`
    mutation MyMutation {
      Otplogin(otp: "${otpdata.otp}", phone_number: "${otpdata.phone_number}") {
        result {
          accessToken
          message
          status
          status_message
          data {
            email
            name
            phone_number
            partner_id
          }
        }
      }
    }`;

    return this.apollo.mutate({
      mutation: otpverify,

    }).pipe(
      tap((data) => {
        data;
      })
    );

  }

  // verifyotp(data) {
  //   return this.apollo.mutate({
  //     mutation: this.VerifyOtpgql.document,
  //     variables: {
  //       'otp': data.otp,
  //       'phone_number': data.phone_number,
  //     }
  //   });
  // }


  ifLoggedIn_otp() {
    const userdata =  Preferences.get({ key: 'user_data' });
    userdata.then(response => {
       if (response.value) {
        // this.authState.next(true);
        this.isAuthenticated.next(true);
        this.router.navigate(['tabs/home']);
      }
    })
  }


  onesignal_id(id,platform_type){
    console.log('c',id)
    console.log('d',platform_type)
    const slot_booking = gql`
    mutation MyMutation {
      insert_one_signal_master(objects: {key: "${id}",signal_type:${platform_type}}) {
        returning {
          id
          key
        }
      }
    }`;

  return this.apollo.mutate({
    mutation: slot_booking,

  }).pipe(
    tap((data) => {
      data;
    })
  );
  }


  register(data) {
    return this.apollo.mutate({
      mutation: this.RegisterUsergql.document,
      variables: {
        'name': data.name,
        'password': data.password,
        'user': 'user',
        'gender':data.gender,
        'mobileno':String(data.mobileno),
        'location':data.location,
        'dob':data.dob.split("-").reverse().join("-"),
        'email':data.email
      }
    });
  }
  emaillogin(data) {
    return this.apollo.mutate({
      mutation: this.loginUsergql.document,
      variables: {
        'email': data.email,
        'password': data.password,
      }
    });
  }
  formatProfilePic(partner_id) {
    let randomnumber = Math.floor(Math.random() * 100000000000000000);
    return `https://telehealth.mo.vc/web/image?model=res.partner&id=${partner_id}&field=image_128&unique=${randomnumber}`;
  }


  logout(): Promise<void> {
    this.productService.emptycart();
    this.isAuthenticated.next(false);
    this.token.next(null);
    this.userdata.next(null);

    Preferences.remove({key: USER_KEY});
    return Preferences.remove({key: TOKEN_KEY});
  }


// forgot password:
forgotpassword(data) {
  return this.apollo.mutate({
    mutation: this.forgotpasswordgql.document,
    variables: {
      'email': data.email,
    }
  });

}




get_freeconsultaion(partner_id){
  const  Schedulecall= gql`
  mutation MyMutation {
    insert_doctor_call_order(objects: {partner_id: "${partner_id}",doctor_call:"yes"}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
   return this.apollo.mutate({
    mutation: Schedulecall,

  }).pipe(
    tap((data) => {
      data;
    })
  );
}




// delete one signal

DeleteOneSignalId(key,user_id){
  const Onesignal = gql`
  mutation DeleteOneSignalId($key: String!,$user_id:Int!) {
    del_onesignals(key: $key, user_id:$user_id) {
      result {
        message
      }
    }
  }`;
    return this.apollo.mutate({
      mutation: Onesignal,
      variables:{'key':key,'user_id':user_id}
    }).pipe(
      tap((data) => {
        data;
      })
    );
}

// Get Partner Zip

GetPartnerZip(partner_id){
  this.res_partner_data= this.apollo
  .watchQuery({
    query: gql`
      {
        res_partner_by_pk(id: ${partner_id}) {
          zip
        }
      }`,
       fetchPolicy:'network-only',
  })
  return this.res_partner_data.valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
}

// Get Latitude and Longitude:

GetLatLong(pincode,partner_id){
  const Pincode = gql`
  mutation update_article($pincode: String!,$partner_id:Int!) {
    update_res_partner(
      where: {id: {_eq: $partner_id}},
      _set: {
        zip: $pincode,

    }
    ) {
      affected_rows
      returning {
        id

  }
    }
  }`;
  return this.apollo.mutate({
    mutation: Pincode,
    variables:{'pincode':pincode,'partner_id':partner_id}
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

// referral content

// referral code
get_referral_content(){
  this.referralcontent= this.apollo
  .watchQuery({
    query: gql`
      {
        referal_master {
          content_1
          content_2
        }
      }`,
      //  fetchPolicy:'network-only',
  })
  return this.referralcontent.valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
}



// referral code
get_referral_code(partner_id){
  this.referral= this.apollo
  .watchQuery({
    query: gql`
      {
        res_partner(where: {id: {_eq:${partner_id}}}) {
          sequence
        }
      }`,
      //  fetchPolicy:'network-only',
  })
  return this.referral.valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
}

check_email_otp(data){
  const EmailOtpCheck = gql`
  mutation get_otp($email: String!,$otp:String!) {
    forgot_paasword_otp(mail_id: $email, otp: $otp) {
      result {
        message
        status
      }
    }
  }`;
  return this.apollo.mutate({
    mutation: EmailOtpCheck,
    variables:{'email':data.email,'otp':data.otp}
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

create_new_password(email,password){
  const NewPassword = gql`
  mutation password_data($email: String!,$password:String!) {
    password_change(mail_id: $email, password: $password) {
      result {
        message
        status
      }
    }
  }`;
  return this.apollo.mutate({
    mutation: NewPassword,
    variables:{'email':email,'password':password}
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

registerPatients(patient_data,token){
  console.log('check1',patient_data,token)
  const patient_POST = gql`
  mutation patient($first_name: String!,$email_id: String!,$dob: date!,$gender : String!,$mobile: String!) {
    insert_patient_registration(objects:{first_name: $first_name, email: $email_id, dob: $dob, gender : $gender , mobile: $mobile}) {
        returning {
        id
        last_name
        first_name
        email
        mobile
      }
    }
  }`;

    return this.apollo.mutate({
      mutation: patient_POST,
      variables: {
        'first_name': patient_data.first_name,
        'email_id':String(patient_data.email),
        'dob':patient_data.dob,
        'gender':patient_data.gender,
        'mobile':patient_data.mobile,
      },
      context: {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
      },
    }).pipe(
      tap((data) => {
        data;
      })
    );
  }

}

