import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';
import { TermsResolver } from './resolvers/terms.resolver';
import { AboutusResolver } from './resolvers/aboutus.resolver';
import { PrivacypolicyResolver } from './resolvers/privacypolicy.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/otp-login',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'product-details/:id',
    loadChildren: () => import('./pages/product-details/product-details.module').then( m => m.ProductDetailsPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'otp-login',
    loadChildren: () => import('./pages/auth/otp-login/otp-login.module').then( m => m.OtpLoginPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'otp-verify',
    loadChildren: () => import('./pages/auth/otp-verify/otp-verify.module').then( m => m.OtpVerifyPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'email-login',
    loadChildren: () => import('./pages/auth/email-login/email-login.module').then( m => m.EmailLoginPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/auth/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/auth/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/myaccount/wallet/wallet.module').then( m => m.WalletPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/myaccount/orders/orders.module').then( m => m.OrdersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'addresses',
    loadChildren: () => import('./pages/cart/selectaddress/selectaddress.module').then( m => m.SelectaddressPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/myaccount/wishlist/wishlist.module').then( m => m.WishlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'terms',
    resolve: { content: TermsResolver },
    loadChildren: () => import('./pages/myaccount/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'aboutus',
    resolve: { content: AboutusResolver },
    loadChildren: () => import('./pages/myaccount/aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    resolve: { content: PrivacypolicyResolver },
    path: 'privacypolicy',
    loadChildren: () => import('./pages/myaccount/privacypolicy/privacypolicy.module').then( m => m.PrivacypolicyPageModule)
  },
  {
    path: 'order-with-prescription',
    loadChildren: () => import('./pages/order-with-prescription/order-with-prescription.module').then( m => m.OrderWithPrescriptionPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'prescription-order-checkout',
    loadChildren: () => import('./pages/prescription-order-checkout/prescription-order-checkout.module').then( m => m.PrescriptionOrderCheckoutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/myaccount/notification/notification.module').then( m => m.NotificationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'prescriptions',
    loadChildren: () => import('./pages/myaccount/prescriptions/prescriptions.module').then( m => m.PrescriptionsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'refer-earn',
    loadChildren: () => import('./refer-earn/refer-earn.module').then( m => m.ReferEarnPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/auth/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'all-new-arrivals',
    loadChildren: () => import('./pages/all-new-arrivals/all-new-arrivals.module').then( m => m.AllNewArrivalsPageModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brands/brands.module').then( m => m.BrandsPageModule)
  },
  {
    path: 'refer',
    loadChildren: () => import('./pages/refer/refer.module').then( m => m.ReferPageModule)
  },
  {
    path: 'product-review',
    loadChildren: () => import('./pages/product-review/product-review.module').then( m => m.ProductReviewPageModule)
  },
  {
    path: 'couponmodalpage',
    loadChildren: () => import('./components/modals/couponmodalpage/couponmodalpage.module').then( m => m.CouponmodalpagePageModule)
  },
  {
    path: 'email-otp',
    loadChildren: () => import('./pages/auth/email-otp/email-otp.module').then( m => m.EmailOtpPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'remark',
    loadChildren: () => import('./remark/remark.module').then( m => m.RemarkPageModule)
  },




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
