import { Injectable } from '@angular/core';
import { map,takeUntil,tap } from 'rxjs/operators';
import {TrackOrdergql,HelpAndSupportgql,HelpAndSupportLinesByIdgql,UpdateProfilePicgql,PrescriptionListgql,erPrescriptionListgql,OrdersByIdgql,MarkAsReadgql,Notificationgql,Privacypolicygql,Aboutusgql,Termsgql,Wishlistgql,Ordersgql,ChangePasswordgql,UpdateUsergql,Usergql,ShippingAddressgql,Countrygql,Stategql,Citygql,InsertShippingAddressgql, UpdateShippingAddressgql,DeleteShippingAddressgql,ShippingAddressByIdgql,SupportChatgql,SendSupportChatgql,WalletHistory,WalletAmount,GetAllCoupons} from './graphql.service';
import {gql,Apollo, QueryRef} from 'apollo-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  get_orders_list:QueryRef<any>;
  constructor(
    private ShippingAddressgql: ShippingAddressgql,
    private Countrygql: Countrygql,
    private Stategql: Stategql,
    private Citygql: Citygql,
    private apollo: Apollo,
    private InsertShippingAddressgql: InsertShippingAddressgql,
    private UpdateShippingAddressgql: UpdateShippingAddressgql,
    private DeleteShippingAddressgql: DeleteShippingAddressgql,
    private ShippingAddressByIdgql: ShippingAddressByIdgql,
    private usergql: Usergql,
    private updateUsergql: UpdateUsergql,
    private changePasswordgql: ChangePasswordgql,
    private Ordersgql: Ordersgql,
    private wishlistgql: Wishlistgql,
    private termsgql: Termsgql,
    private aboutusgql: Aboutusgql,
    private privacypolicygql: Privacypolicygql,
    private SupportChatgql: SupportChatgql,
    private SendSupportChatgql: SendSupportChatgql,
    private notificationgql: Notificationgql,
    private markAsReadgql: MarkAsReadgql,
    private ordersByIdgql: OrdersByIdgql,
    private prescriptionListgql: PrescriptionListgql,
    private erPrescriptionListgql: erPrescriptionListgql,
    private updateProfilePicgql: UpdateProfilePicgql,
    private helpAndSupportgql: HelpAndSupportgql,
    private helpAndSupportLinesByIdgql: HelpAndSupportLinesByIdgql,
    private trackOrdergql: TrackOrdergql,
    private walletHistory: WalletHistory,
    private walletAmount: WalletAmount,
    private getAllCoupons: GetAllCoupons,
  ) { }


  getallShippingAddress() {
    return this.shippingaddressQuery().valueChanges
    .pipe(
      map(result => result.data.partner_shipping_address)
    );
  }

  shippingaddressQuery() {
    return this.ShippingAddressgql.watch({},{
      fetchPolicy: 'network-only'
    });
  }

  getallCountry() {
    return this.Countrygql.watch({})
    .valueChanges
    .pipe(
      map(result => result.data.res_country)
    );
  }

  // get state by country id
  getStateByCountryId(countryId: number) {
    return this.Stategql.watch({
      country_id: { _eq: countryId }
    }).valueChanges
    .pipe(
      map(result => result.data.res_country_state)
    );
  }

  // get cities by state id
  getCitiesByStateId(stateId: number) {
    return this.Citygql.watch({
      state_id:  { _eq: stateId }
    }).valueChanges
    .pipe(
      map(result => result.data.res_city)
    );
  }


  //insert ar update shipping address
  insertShippingAddress(shippingAddress: any) {
    return this.apollo.mutate({
    mutation: this.InsertShippingAddressgql.document,
    variables: {
     //id: shippingAddress.id,
      name: shippingAddress.name,
      street: shippingAddress.street,
      street2: shippingAddress.street2,
      city: shippingAddress.city.id,
      state_id: shippingAddress.state_id.id,
      country_id: shippingAddress.country_id.id,
      email: shippingAddress.email,
      mobile: shippingAddress.mobile,
      zip: shippingAddress.zip.toString(),
  }});

}
updateShippingAddress(shippingAddress: any) {
  console.log("shippingAddress",shippingAddress);
  return this.apollo.mutate({
  mutation: this.UpdateShippingAddressgql.document,
  variables: {
   id: shippingAddress.id,
    name: shippingAddress.name,
    street: shippingAddress.street,
    street2: shippingAddress.street2,
    city: shippingAddress.city.id,
    state_id: shippingAddress.state_id.id,
    country_id: shippingAddress.country_id.id,
    email: shippingAddress.email,
    mobile: shippingAddress.mobile,
    zip: shippingAddress.zip.toString()
}});

}


deleteShippingAddress(id: number) {
  return this.apollo.mutate({
  mutation: this.DeleteShippingAddressgql.document,
  variables: {
    id: id
  }});
}

getShippingAddressById(id: number) {
  return this.ShippingAddressByIdgql.watch({
    id: { _eq: id }
  }).valueChanges
  .pipe(
    map(result => result.data.partner_shipping_address)
  );
}

getUser()
{
  return this.usergql.watch({}
    ,{
      fetchPolicy: 'network-only'
    }
    ).valueChanges
  .pipe(
    map(result => result.data)
  );
}
editProfile(user: any) {
  return this.apollo.mutate({
    mutation: this.updateUsergql.document,
    variables: {
      name: user.name,
    }
  });
}

changePassword(password: any) {
  return this.apollo.mutate({
    mutation: this.changePasswordgql.document,
    variables: {
      old_password: password.old_password,
      password: password.password
    }
  });
}
getOrders(limit: number, offset: number) {
  return this.Ordersgql.watch({
    limit: limit,
    offset: offset
  },{
    fetchPolicy: 'network-only'
  }).valueChanges.pipe(map(result => result.data.sale_order));
}

refetch_orders(limit=10, offset=0){
  this.get_orders_list=this.apollo.watchQuery({
    query: gql`{
      sale_order(order_by: {date_order: desc}, offset: ${offset}, limit: ${limit}) {
        id
        name
        amount_total
        create_date
        amount_tax
        date_order
        status
        Payment_status
        payment_date
        payment_mode
        partner_shipping_id
        sale_order_lines {
          product_id
          product_uom_qty
          product_uom
        }
        ir_attachment_sale_order_rels {
          ir_attachment_id
        }
        sale_order_lines_aggregate {
          aggregate {
            count
          }
        }
      }
    }`,
    fetchPolicy:'network-only'
    });
    return this.get_orders_list.valueChanges.pipe(
      tap((data) => {
          data;
      })
    );
}




getWishlistItems()
{
  return this.wishlistgql.watch({
    },{
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(
      map(result => result.data.wishlist_product_id)
      );
}

getTerms()
{
  return this.termsgql.watch({
    }).valueChanges
      .pipe(
        map(result => result.data.tele_terms)
        );
}

getAboutus()
{
  return this.aboutusgql.watch({
    }).valueChanges
      .pipe(
        map(result => result.data.about_master)
        );
}

getPrivacyPolicy(){
  return this.privacypolicygql.watch({
    }).valueChanges
      .pipe(
        map(result => result.data.about_master)
        );
}

// get support chat
getSupportChat() {
  return this.SupportChatgql.subscribe({
  },{
    fetchPolicy: 'network-only'
  }).pipe(
    tap((data) => {
        data;
    })
    );
  }
  sendMessage(message){
    return this.apollo.mutate({
        mutation: this.SendSupportChatgql.document,
        variables: message
      }).pipe(
        tap((data) => {
            data;
        })
        );
    }

  //get notification subscription
  getNotifications() {
    return this.notificationgql.subscribe({
    },{
      fetchPolicy: 'network-only'
    }).pipe(
      map(result => result.data.tele_notification)
      );
    }

    // mark notification as read
    markNotificationAsRead(id: number) {
      return this.apollo.mutate({
        mutation: this.markAsReadgql.document,
        variables: {
          id: id
        }
      });

    }

    // get order by id
    getOrdersById(id: number) {
      console.log("id",id);
      return this.ordersByIdgql.watch({
        _eq: id
      }).valueChanges
      .pipe(
        map(result => result.data.sale_order[0])
      );
    }


    //get Prescriptions
    getPrescriptions(limit: number, offset: number,user_id: number) {
      return this.prescriptionListgql.watch({
        limit: limit,
        offset: offset,
        user_id:user_id,
      },{
        fetchPolicy: 'network-only'
      }).valueChanges
      .pipe(
        map(result => result.data.prescription_upload)
        );
      }
      getErPrescriptions(limit: number, offset: number,partner_id: number) {
        return this.erPrescriptionListgql.watch({
          limit: limit,
          offset: offset,
          partner_id:partner_id,
        },{
          fetchPolicy: 'network-only'
        }).valueChanges
        .pipe(
          map(result => result.data.prescription_detail)
          );
        }

      updateProfilePic(image: any) {
        return this.apollo.mutate({
          mutation: this.updateProfilePicgql.document,
          variables: {
            pro_image: image
          }
        });

      }
      // get help and support
      getHelpAndSupport() {
        return this.helpAndSupportgql.watch({
        }).valueChanges
        .pipe(
          map(result => result.data.help_support)
          );

        }

        // get help and support by id
        getHelpAndSupportById(id: number) {
          return this.helpAndSupportLinesByIdgql.watch({
            _eq: id
          }).valueChanges
          .pipe(
            map(result => result.data.help_support[0])
          );
        }

        //track order
        trackOrder(id: number) {
          return this.trackOrdergql.watch({
            _eq: id
          }).valueChanges
          .pipe(
            map(result => result.data.sale_order_log)
          );
        }

        getWalletHistory() {
          return this.walletHistory.watch({
          },{
            fetchPolicy: 'network-only'
          }).valueChanges
          .pipe(
            map(result => result?.data?.account_wallet?.[0].account_move_lines)
            );

          }

        getWalletAmount() {
          return this.walletAmount.watch({
          },{
            fetchPolicy: 'network-only'
          }).valueChanges
          .pipe(
            map(result =>result?.data?.account_wallet?.[0]?.balance)
            );

          }

        Coupons(partner_id) {
          return this.apollo.mutate({
            mutation: this.getAllCoupons.document,
            variables:{partner_id:partner_id}
          });
        }

        couponValidation(validation_details){
          const coupon_detail = gql`
          mutation coupon_validation(
            $coupon_id: Int!,
            $partner_id: Int!,
            $sale_amount:String!,
            $types:String!,
            ) {
              salepaydetail(coupon_id: $coupon_id, partner_id: $partner_id,sale_amount:$sale_amount,types:$types) {
                result {
                  status
                  new_amount
                  message
                  discount_amount
                  coupon_id
                  name
              }
            }
          }`;
            return this.apollo.mutate({
              mutation: coupon_detail,
              variables: validation_details
            }).pipe(
              tap((data) => {
                data;
              })
            );
        }


}
