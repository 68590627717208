import { Injectable } from '@angular/core';
import {GetCartgql,EmptyCartgql,Filtersgql,Wishlistgql,WishlistAddgql,Productsgql,Productgql,WishlistRemovegql,AddToCartgql,UpdateCartQuantitygql,DeleteCartItemByProductIdgql } from './graphql.service';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Preferences } from '@capacitor/preferences';

import { Apollo, gql } from 'apollo-angular';

import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
const CART_KEY = 'healthuno-marketplace-cart';
const WISHLIST_KEY = 'healthuno-marketplace-wishlist';
const TOKEN_KEY = 'healthuno-marketplace-token';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private cart = new BehaviorSubject([]);
  public wishlist = new BehaviorSubject([]);
  private wishlistItemCount = new BehaviorSubject([]);
  private cartItemCount = new BehaviorSubject(0);
  private opensearch = new BehaviorSubject(false);
  private cartitems = [];
  private wishlistitems = [];
  addcartcount: any;
  constructor(
    private productsgql: Productsgql,
    private productgql: Productgql,
    private notificationService: NotificationService,
    private apollo: Apollo,
    private wishlistAddgql: WishlistAddgql,
    private wishlistRemovegql: WishlistRemovegql,
    private filtersgql: Filtersgql,
    private wishlistgql: Wishlistgql,
    private addToCartgql: AddToCartgql,
    private updateCartQuantity: UpdateCartQuantitygql,
    private deleteCartItemByProductId: DeleteCartItemByProductIdgql,
    private emptyCartgql: EmptyCartgql,
    private getCartgql: GetCartgql,
    private router: Router
      ) {

    this.loadCart();
    this.loadWishlist();
   }
  getProducts(filter,limit,offset,sort) {
    console.log('filter',filter)
    const categoryIdWhere = filter.category_id ? { _eq: filter.category_id }  : {};
    const namewhere = filter.search ? { _ilike: `%${filter.search}%` } : {};
    const brandIdWhere = filter.brand_id ? { _eq: filter.brand_id } : {};
    const fastmovingWhere = filter.fast_moving ? { _eq: "f" } : {};
    const healthconditionWhere=filter.symptoms_id ?  {_eq: filter.symptoms_id}:{};
    // const healthconditionWhere=filter.health_condition_id ?  {_eq: filter.health_condition_id}:{};
    const sortconditionWhere=sort ? sort:'desc';
    console.log(categoryIdWhere);

    const approveWhere={ _eq: "approve" }
    // min and max price
    // {brand_name: {_eq: 14}
    console.log('myhealthcondition',healthconditionWhere)
    const priceWhere = filter?.price ? { _gte: filter.price.lower, _lte: filter.price.upper } : {};
    return this.productsgql.watch({ limit:limit, offset: offset, categoryIdWhere: categoryIdWhere, namewhere: namewhere, brandIdWhere: brandIdWhere, priceWhere: priceWhere, fastmovingWhere: fastmovingWhere,prescription: healthconditionWhere,stages:approveWhere,sort_data:{id:sortconditionWhere}},
      {
        fetchPolicy: 'network-only'
      }
      )
    .valueChanges
    .pipe(
      map(result => result.data)
    );
  }

  getProduct(id) {
    return this.productgql.watch({ id },{
      fetchPolicy: 'network-only'
    })
    .valueChanges
    .pipe(
      map(result => result.data.product_template_by_pk)
    );
  }

  loadCart() {
    Preferences.get({ key: CART_KEY }).then(result => {
      if (result.value) {
        this.cartitems = JSON.parse(result.value);
        this.cart.next(this.cartitems);
        this.cartItemCount.next(this.cartitems.length);
      }
    }
    ).catch(err => {
      console.log(err);
    });
  }

  get Wishlist() {
    // pipe total
    return this.wishlist.asObservable();
  }

get cartData() {
  // pipe total
  return this.cart.asObservable();
}
get getcartcount() {
  return this.cartItemCount.asObservable();
}
// async setsaveforlater(product,status){
//   let itemIndex = this.cartitems.findIndex(item => item.product.id === product.id);
//   this.cartitems[itemIndex].saveforlater=status;
//   this.cartitems[itemIndex].save_later_quantity=this.cartitems[itemIndex].quantity;
//   this.cartitems[itemIndex].quantity=0
//   this.SaveForLaterProducts(product.id,true,this.cartitems[itemIndex].quantity,this.cartitems[itemIndex].save_later_quantity).subscribe((data)=>{
//     console.log("resultsett"+data)

//   })
//   this.saveCart();
// }
// async movetocart(product,status){
//   let itemIndex = this.cartitems.findIndex(item => item.product.id === product.id);
//   console.log(itemIndex)
//   this.cartitems[itemIndex].saveforlater=false;
//   this.cartitems[itemIndex].quantity=this.cartitems[itemIndex].quantity+this.cartitems[itemIndex].save_later_quantity
//   this.cartitems[itemIndex].save_later_quantity=0
//   this.SaveForLaterProducts(product.id,false,this.cartitems[itemIndex].quantity,this.cartitems[itemIndex].save_later_quantity).subscribe((data)=>{
//     console.log("resultmove"+data)
//   })

//   this.saveCart();


// }
  async addToCart(product) {
      let token = await (await Preferences.get({ key:TOKEN_KEY })).value;
      if(!token){
        this.notificationService.presentToast('Please login to add to cart');
        this.router.navigate(['/otp-login']);
        return false;
      }
    // check if product already exists in cart
    let itemIndex = this.cartitems.findIndex(item => item.product.id === product.id);

    if (itemIndex > -1) {
      this.cartitems[itemIndex].quantity++;
      this.apollo.mutate({
        mutation: this.updateCartQuantity.document,
        variables: {
          'product_id': product.id,
          'quantity':  this.cartitems[itemIndex].quantity

        }
      }).subscribe(result => {
        console.log('1',result);
      });
    } else {
      this.cartitems.push({ product: product, quantity: 1 });
      // this.cartitems.push({ product: product, quantity: 1 ,saveforlater: false,save_later_quantity:0});
      console.log(' product.id', product.id)
      this.addToCartgql.document,
      this.apollo.mutate({
        mutation: this.addToCartgql.document,
        variables: {
          'products': product.id,
          'quantity': 1,
          // 'save_later_quantity':0,

        }
      }).subscribe(result => {
 
      });
    }
    this.notificationService.presentToast('Product added to cart');

    this.saveCart();
    return true;
  }

  // save cart to local storage
  saveCart() {
    Preferences.set({ key: CART_KEY, value: JSON.stringify(this.cartitems) });
    this.cart.next(this.cartitems);
    this.cartItemCount.next(this.cartitems.length);
    this.loadCart();
    console.log('cartitem',this.cartitems);
  }


  // increase product quantity
  increaseQuantity(product) {
    console.log(product);
      let itemIndex = this.cartitems.findIndex(item => item.product.id === product.id);
      if (itemIndex > -1) {
        this.cartitems[itemIndex].quantity++;
      }

      this.apollo.mutate({
        mutation: this.updateCartQuantity.document,
        variables: {
          'product_id': product.id,
          'quantity':  this.cartitems[itemIndex].quantity
        }
      }).subscribe(result => {
        console.log(result);
      });
      this.saveCart();
  }
  // decrease product quantity
  decreaseQuantity(product) {
      let itemIndex = this.cartitems.findIndex(item => item.product.id === product.id);
      if (itemIndex > -1) {
        this.cartitems[itemIndex].quantity--;

        this.apollo.mutate({
          mutation: this.updateCartQuantity.document,
          variables: {
            'product_id': product.id,
            'quantity':  this.cartitems[itemIndex].quantity
          }
        }).subscribe(result => {
          console.log(result);
        });

        if (this.cartitems[itemIndex].quantity === 0) {
          console.log(product);

          this.removeFromCart(product);
          //this.cartitems.splice(itemIndex, 1);
        }
      }
   this.saveCart();

  }
  
  //get cart total as observable
  get cartTotal() {
    return this.cart.pipe(
      map(cart => cart.reduce((acc, curr) => acc + curr.quantity * curr.product.price, 0))
    );
  }
  // remove product from cart
  removeFromCart(product) {
      let itemIndex = this.cartitems.findIndex(item => item.product.id === product.id);
      console.log(itemIndex);
      if (itemIndex > -1) {
        this.cartitems.splice(itemIndex, 1);
      }
      this.apollo.mutate({
        mutation: this.deleteCartItemByProductId.document,
        variables: {
          'product_id': product.id
        }
      }).subscribe(result => {
        console.log(result);
      });
      this.notificationService.presentToast('Product removed from cart');
      this.saveCart();
      
  }

  removeFromWishlist(product: any)
  {
    // Set localstorage in wishlist
    let itemIndex = this.wishlistitems.findIndex(item => item.product.id === product.id);
    console.log(itemIndex);
    if (itemIndex > -1) {
      this.wishlistitems.splice(itemIndex, 1);
    }

    this.apollo.mutate({
      mutation: this.wishlistRemovegql.document,
      variables: {
        'product_id': product.id,
      }
    }).subscribe(result => {
      this.wishlistgql.watch({},{
        fetchPolicy: 'network-only'
      }).refetch();
      this.notificationService.presentToast(product.name+' removed from wishlist');
      this.savewishlist();
    });
  }






  async addToWishlist(product:any) {
    // check if product already exists in wishlist
    let token = await (await Preferences.get({ key:TOKEN_KEY })).value;
    if(!token){
      this.notificationService.presentToast('Please login to add wishlist');
      this.router.navigate(['/otp-login']);
      return false;
    }

    let itemIndex = this.wishlistitems.findIndex(item => item.product.id === product.id);

    if (itemIndex > -1) {
      this.wishlistitems[itemIndex].quantity++;
      this.apollo.mutate({
        mutation: this.wishlistAddgql.document,
        variables: {
          'product_id': product.id,

        }
      }).subscribe(result => {
        console.log('1',result);
      });
    } else {
    this.wishlistitems.push({ product: product });
    // this.savewishlist();

    this.apollo.mutate({
      mutation: this.wishlistAddgql.document,
      variables: {
        'product_id': product.id,
      }
    }).subscribe(result => {
      this.notificationService.presentToast(product.name+' added to wishlist');
      this.savewishlist();
      return true;

    });
    return true;
  }

}

  openSearch(){
    this.opensearch.next(true);
  }
  closeSearch(){
    this.opensearch.next(false);
  }
  getSearch(){
    return this.opensearch.asObservable();
  }




  getFilters() {
    return this.filtersgql.watch()
    .valueChanges
    .pipe(
      map(result => result.data)
    );
  }
  clearCart(){
    this.apollo.mutate({
      mutation: this.emptyCartgql.document,
    }).subscribe(result => {
      console.log(result);
    });
    this.emptycart();
  }

  emptycart(){
    this.cartitems = [];
    this.saveCart();
  }

  loadcartfromserver(){
    this.apollo.watchQuery({
      query: this.getCartgql.document,
      fetchPolicy: 'network-only'
    },).valueChanges.subscribe((result:any) => {
      this.cartitems = result.data.add_cart;
      console.log(this.cartitems);
      this.saveCart();
    }
    );
  }
  getproductvariant(product_id)
  {
    return this.apollo
    .watchQuery({
      query: gql`{
        product_product(where: {id: {_eq: ${product_id}}}) {
          id
          weight
          product_template {
            name
            id
          }
        }
    }`,
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      })
    );
  }
  getFeaturedCategories()
  {
    return this.apollo
    .watchQuery({
      query: gql`{
        product_category(where: {featured_category: {_eq: true}},limit:5) {
          name
          id
        }
    }`,
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      })
    );
  }

  getBrands(limit,offset,categ_id) {
  console.log('id',categ_id);
  const categoryIdWhere= categ_id ? { _eq: categ_id }  : {_is_null: false};
  console.log('asd',categoryIdWhere)

   console.log('{product_brand: {categ_id:'+categoryIdWhere+'}}');
    return this.apollo
    .watchQuery({
      query: gql`
        query allBrands($categ_id: Int_comparison_exp,$limit: Int, $offset: Int) {
          brand_master(where: {product_brand: {categ_id:$categ_id}},limit:$limit,offset:$offset) {
            name
            id
          }
    }`,
    variables:{
      categ_id:categoryIdWhere,
      limit:limit,
      offset:offset


    }
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      }
    ));
  }


  // Check Product Exist in Cart
  CheckProductInCart(product_id) {
     console.log(product_id);
    return this.apollo
    .watchQuery({
      query: gql` {
        add_cart(where: {product: {id: {_eq: ${product_id} }}}) {
          id
        }
    }`,
    fetchPolicy: 'network-only'
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      }
    ));
  }


// Most Selling Products:
MostSellingProducts() {
  return this.apollo
  .watchQuery({
    query: gql` {
      product_template(where: {fast_moving: {_eq: "f"},stages:{_eq:"approve"}}) {
       id
       list_price
       name
       mrp
       net_qty
       new_informations
       Marketers
       brand_name
       packsize
       procured_by
       ingredient
       directions_to_use
       item_weight
       safety_precaution
     }

  }`,
  fetchPolicy: 'network-only'
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    }
  ));

}


// Save for Later Products:
SaveForLaterProducts(id,status,quantity,save_later_quantity) {
    console.log('cart_id',id)
    const save_for_later = gql`
    mutation MyMutation {
      update_add_cart(where: {products: {_eq: ${id} }}, _set: {saveforlater: ${status},save_later_quantity:${save_later_quantity},quantity:${quantity}}) {
        affected_rows
        returning {
          saveforlater
          id
        }
      }
      }`
    return this.apollo.mutate({
      mutation: save_for_later,
    }).pipe(
      tap((data) => {
        data;
      })
    );
  }



//   GetsaveforlaterProducts() {
//     return this.apollo
//     .watchQuery({
//       query: gql` {
//         add_cart(where: {saveforlater: {_eq: false}}) {
//           saveforlater
//           id
//           products
//         }
//     }`,
//     fetchPolicy: 'network-only'
//     })
//     .valueChanges.pipe(
//       tap((data) => {
//           data;
//       }
//     ));
// }

AddReview(data) {
  const add_review = gql`
  mutation MyMutation ($comments:String!,$product_id:Int!,$rating:Int!,$review_documents:String!,$user_id:Int!,$order_id:Int!){
    product_review(comments:$comments,product_id:$product_id,rating:$rating,review_documents:$review_documents,user_id:$user_id,order_id:$order_id) {
      result {
        message
        status
      }
    }
  }`
  return this.apollo.mutate({
    mutation: add_review,
    variables:{'comments':data.comments,'product_id':data.product_id,'rating':data.rating,'review_documents':data.review_documents,'user_id':data.user_id,'order_id':data.order_id}
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

// check product review
  GetProductReview(user_id,product_id) {
    return this.apollo
    .watchQuery({
      query: gql` {
        customer_review(where: {res_user: {id: {_eq: ${user_id}}}, product_id: {_eq: ${product_id}}}) {
          id
          name
          rating
          customer_review_ir_attachment_rels {
            ir_attachment_id
          }
        }
    }`,
    fetchPolicy: 'network-only'
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      }
    ));
}

// Cancel Order:
CancelOrder(id,remark) {
  console.log(remark);
  const cancel_order = gql`
  mutation MyMutation {
    sale_cancel(remark: ${remark}, sale_id: "${id}") {
      result {
        status
      }
    }

  }`
  return this.apollo.mutate({
    mutation: cancel_order,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

  // Set localstorage in wishlist
  savewishlist(){
    Preferences.set({ key: WISHLIST_KEY, value: JSON.stringify(this.wishlistitems) });
    this.wishlist.next(this.wishlistitems);
    this.loadWishlist();
    console.log('wishlistitem1',this.wishlistitems); 
  }

  loadWishlist() {
    Preferences.get({ key: WISHLIST_KEY }).then(result => {
      if (result.value) {
        this.wishlistitems = JSON.parse(result.value);
        this.wishlist.next(this.wishlistitems);
        console.log('wishlistitem2',this.wishlistitems); 
      }
    });
  }




}
