import { Injectable } from '@angular/core';
import { AccountService } from '@services/account.service';

import {
 Resolve, 
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TermsResolver implements Resolve<any> {
  constructor(
    private accountsService: AccountService,
  )
  { }
  resolve(){

      return new Promise((resolve, reject) => {
        this.accountsService.getTerms().subscribe((terms:any)=> {
          console.log(terms);
             if(terms){

               resolve(terms[0].terms)
             } else {
              resolve("Loading terms failed");
              console.log("No terms found");
             }
          });                
     });
 
    }
}
