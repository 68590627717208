import { Injectable } from '@angular/core';
import { AccountService } from '@services/account.service';

import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutusResolver implements Resolve<any> {
  constructor(
    private accountsService: AccountService,
  )
{

}
  resolve(){
    return new Promise((resolve, reject) => {
      this.accountsService.getAboutus().subscribe((aboutus:any)=> {
        console.log(aboutus);
           if(aboutus){
             resolve(aboutus[0].descrption)
           } else {
            resolve("Loading terms failed");
            console.log("No terms found");
           }
        });                
   });

  }
}
