import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { pageTransition } from './custom-animation';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { ImagePipe } from 'src/app/pipes/image.pipe';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { AppLauncher } from '@ionic-native/app-launcher/ngx';
import { SwiperModule } from 'swiper/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,IonicModule.forRoot({ navAnimation: pageTransition }), AppRoutingModule, GraphQLModule, HttpClientModule,SwiperModule,IonicSelectableModule],
  providers: [NativeGeocoder,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Network,Camera,File,Chooser,HTTP,FileOpener,DatePicker,PhotoViewer,ImagePipe,Clipboard,
    AppLauncher,ScreenOrientation,SmsRetriever,Geolocation ],
  bootstrap: [AppComponent],
})
export class AppModule {}
