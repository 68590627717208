import { Injectable } from '@angular/core';
import { Query, gql, Subscription } from 'apollo-angular';

export interface Category {
  id: number;
  name: string;
}
export interface CategoryResponse {
  product_category: Category[];
}

@Injectable({
  providedIn: 'root'
})
export class Categorygql extends Query<CategoryResponse> {
  //query with limit and offset
  document = gql`
  query allCategories($limit: Int, $offset: Int) {
    product_category(limit: $limit, offset: $offset,order_by: {name: asc}, where: {website_view: {_eq: true}}) {
      id
      name
    }
  }`;

}

// product
export interface Product {
  id: number;
  name: string;
  list_price: number;
  full_price: number;
  website_description: string;
  manufacturer_name: string;
  procured_by: string;
  page_descrip: string;
  package_type: string;
  images: number[];
}

export interface ProductsResponse {
  product_template: Product[];
  product_template_aggregate: any;
}
@Injectable({
  providedIn: 'root'
})
export class Productsgql extends Query<ProductsResponse> {
  document = gql`
  query allProducts($limit: Int, $offset: Int, $categoryIdWhere: Int_comparison_exp, $brandIdWhere: Int_comparison_exp, $namewhere: String_comparison_exp,$priceWhere: numeric_comparison_exp,$fastmovingWhere: String_comparison_exp,$stages: String_comparison_exp,$prescription: Int_comparison_exp,$sort_data:product_template_order_by!) {
    product_template(where:{categ_id:$categoryIdWhere,name:$namewhere,brand_name:$brandIdWhere,list_price: $priceWhere,fast_moving:$fastmovingWhere,stages:$stages,prescription:$prescription},limit: $limit, offset: $offset,order_by:[$sort_data]) {
      id
      over_all
      name
      list_price
      full_amount
      product_discount
      name
      mrp
      net_qty
      new_informations
      Marketers
      brand_name
      packsize
      procured_by
      ingredient
      directions_to_use
      expiration_date
      item_weight
      safety_precaution
      stages
      uom_id
      package_type
      status_id
      packsize_uom {
        id
        name
      }
      packingMasterByPackingMaster {
        name
      }
      product_products {
        id
      }
      wishlistproduct {
        id
      }
    }
    product_template_aggregate {
      aggregate {
        min {
          list_price
        }
        max {
          list_price
        }
      }
    }
    product_category {
      id
      name
    }

    brand_master(where: {product_brand: {categ_id: $categoryIdWhere}}, limit: 50) {
        name
        id
      }
    symptoms_master {
      id
      name
    }
    prescription_category {
      id
      name
    }
  }`;
}
// product details
export interface ProductResponse {
  product_template_by_pk: Product;
}
@Injectable({
  providedIn: 'root'
})
export class Productgql extends Query<ProductResponse> {
  document = gql`
  query GetProduct($id: Int!) {
    product_template_by_pk(id: $id) {
      id
      name
      list_price
      full_amount
      product_discount
      full_price
      website_description
      manufacturer_name
      package_type
      procured_by
      page_descri
      flavour
      packing_master
      packsize
      status_id
      packsize_uom {
        id
        name
      }
      net_qty
      ingredient
      country_of_origin
      directions_to_use
      item_weight
      expiration_date
      hsn_code
      uom_id
      product_products {
        id
      }
      productAlternativeRelsBySrcId {
        product_template {
          id
          list_price
          name
        }
      }
      new_informations
      sideeffect_formdescripitions
      therapeutic_descripitions
      indication
      warning_formdescripitions
      moreinfo_formdescripitions
      interactions_formdescripitions
      direction_formdescripitions
      description_otc
      key_ingredients
      keybenefits_otc
      dosage_otc
      Precaution_otc
      other_otc
      images {
        id
      }
      wishlistproduct {
        id
      }
      product_template_products_varients_masters_rels {
        product_template_id
        products_varients_master_rel_many2many_product {
          brand_master {
            id
            name
          }
          varients_masters {
            packing_master_varients_masters_rels {
              packing_master_varient_master {
                id
                name
                packing_master_product_template_rels {
                  product_template_id
                }
              }
            }
            flavour_master_varients_masters_rels {
              flavour_master {
                id
                name
                flavour_master_product_template_rels {
                  product_template_id
                }
              }
            }
            size_master_varients_masters_rels {
              size_master {
                id
                name
                product_template_size_master_rels {
                  product_template_id
                }
              }
            }
          }
        }
      }

    }
  }`;
}
// export class Productgql extends Query<ProductResponse> {
//   document = gql`
//   query GetProduct($id: Int!) {
//     product_template_by_pk(id: $id) {
//       id
//       name
//       list_price
//       full_price
//       website_description
//       manufacturer_name
//       package_type
//       procured_by
//       page_descri
//       flavour
//       packing_master
//       packsize
//       packsize_uom {
//         id
//         name
//       }
//       net_qty
//       ingredient
//       country_of_origin
//       directions_to_use
//       item_weight
//       expiration_date
//       hsn_code
//       uom_id
//       product_products {
//         id
//       }
//       productAlternativeRelsBySrcId {
//         product_template {
//           id
//           list_price
//           name
//         }
//       }
//       sideeffect_formdescripitions
//       therapeutic_descripitions
//       warning_formdescripitions
//       moreinfo_formdescripitions
//       interactions_formdescripitions
//       direction_formdescripitions
//       images {
//         id
//       }
//       wishlistproduct {
//         id
//       }
//       products_varients_masters(where: {product_id: {_eq:  $id}}) {
//         id
//         brand_master {
//           name
//           id
//         }
//         varients_masters {
//           id
//           packing_master {
//             id
//             name
//             product_templates(where: {brand_name: {_eq:  $id}}) {
//               id
//               name
//             }
//           }
//           size_master_varients_masters_rels {
//             size_master {
//               name
//               id
//               product_template_size_master_rels {
//                 product_template {
//                   id
//                   name

//                 }
//               }
//             }
//           }
//           flavour_master_varients_masters_rels {
//             flavour_master {
//               id
//               name
//               flavour_master_product_template_rels {
//                 product_template_id
//                 product_template {
//                   name
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }`;
// }

export interface Subslider {
  id: number;
  name: string;
}

export interface SubsliderResponse {
  product_sub_slidders: Subslider[];
}
@Injectable({
  providedIn: 'root'
})
export class Subslidergql extends Query<SubsliderResponse> {
  document = gql`
  query SubsliderOne {
    brand_master(where: {slidder_one: {_eq: true}}) {
      id
      name
      slug_url
    }
  }`;
}

// product_sublevel_slidders
export interface Sublevelslider {
  id: number;
  name: string;
}

export interface SublevelsliderResponse {
  product_sublevel_slidders: Sublevelslider[];
}
@Injectable({
  providedIn: 'root'
})
export class Sublevelslidergql extends Query<SublevelsliderResponse> {
  document = gql`
  query SubsliderTwo {
    brand_master(where: {slidder_two: {_eq: true}}) {
      id
      name
      slug_url
    }
  }`;
}

//brands
export interface Brand {
  id: number;
  name: string;
}

export interface BrandResponse {
  product_web_brand: Brand[];
}
@Injectable({
  providedIn: 'root'
})
export class Brandgql extends Query<BrandResponse> {
  document = gql`
    query allBrand($limit: Int, $offset: Int) {
      brand_master(where: {top_brand: {_eq: true}},limit: $limit, offset: $offset) {
      id
      name
      slug_url
    }
  }`;
}

//symptoms
export interface Symptom {
  id: number;
  name: string;
}

export interface SymptomResponse {
  prescription_category: Symptom[];
}
@Injectable({
  providedIn: 'root'
})
export class Symptomgql extends Query<SymptomResponse> {
  document = gql`
  query allSymptom($limit: Int, $offset: Int) {
    prescription_category(limit: $limit, offset: $offset) {
      name
      id
      slug_url
    }
  }`;
}


// register user
export interface RegisterUser {
  result: {
    accessToken: string;
    message: string;
    data: User;
  }
}
@Injectable({
  providedIn: 'root'
})
export class RegisterUsergql extends Query<RegisterUser> {
  document = gql`
  mutation RegisterUser($mobileno: String, $location: String, $gender: String, $dob: String, $password: String!, $user: String!, $name: String!, $email: String!) {
    register(name: $name, password: $password, user: $user, gender: $gender, dob: $dob, location: $location, mobileno: $mobileno, email: $email) {
      result {
        accessToken
        status
        message
        data{
          name
          email
          phone_number
          partner_id
        }
      }
    }
  }`;

}

// send otp
export interface SendOtp {
  result: {
    message: string;
    status: string;
  }
}
@Injectable({
  providedIn: 'root'
})

export class SendOtpgql extends Query<SendOtp> {
  document = gql`
  mutation SendOtp($phone_number: String!,$sms_code: String!) {
    sendOtp(phone_number: $phone_number,sms_code:$sms_code) {
      result {
        message
        status
        sms_code
      }
    }
  }`;
}

export interface User {
  name: string;
  email: string;
  phone_number: string;
}
// verify otp
export interface VerifyOtp {
  result: {
    message: string;
    status: string;
    accessToken: string;
    data: User;
  }
}
@Injectable({
  providedIn: 'root'
})
export class VerifyOtpgql extends Query<VerifyOtp> {
  document = gql`
  mutation login($phone_number: String!,$otp: String!) {
    Otplogin(phone_number: $phone_number,otp: $otp) {
      result {
        accessToken
        message
        status
      }
    }



  }`;
}
// mutation VerifyOtp($phone_number: String!, $otp: String!) {
//   Otplogin(otp: $otp, phone_number: $phone_number) {
//     result {
//       accessToken
//       message
//       status
//       data{
//         name
//         email
//         phone_number
//         partner_id
//       }
//     }
//   }

// login user
export interface LoginUser {
  result: {
    accessToken: string;
    message: string;
    data: User;
  }
}
@Injectable({
  providedIn: 'root'
})
export class LoginUsergql extends Query<LoginUser> {
  document = gql`
  mutation LoginUser($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      result {
        accessToken
        message
        status
        status_message
        data{
          name
          email
          phone_number
          partner_id
        }
      }
    }
  }`;
}



//get shipping address
export interface ShippingAddress {
  id: number;
  name: string;
  street: string;
  street2: string;
  city_id: number;
  state_id: number;
  country_id: number;
  email: string;
  mobile: string;
  zip: string;
}

export interface ShippingAddressResponse {
  partner_shipping_address: ShippingAddress[];
}
@Injectable({
  providedIn: 'root'
})
export class ShippingAddressgql extends Query<ShippingAddressResponse> {
  document = gql`
  query allShippingAddress {
    partner_shipping_address(where: {active: {_eq: true}}) {
      id
      name
      street
      street2
      city_id
      active
      state{
        id
        name
      }
      res_city{
        id
        city_name
      }
      country{
        id
        name
      }
      email
      mobile
      zip
    }
    }`;
}


// get all Country
export interface Country {
  id: number;
  name: string;
}

export interface CountryResponse {
  res_country: Country[];
}
@Injectable({
  providedIn: 'root'
})
export class Countrygql extends Query<CountryResponse> {
  document = gql`
  query allCountry {
    res_country  {
      id
      name
    }
  }`;
}



// get all State by country id
export interface State {
  id: number;
  name: string;
}

export interface StateResponse {
  res_country_state: State[];
}
@Injectable({
  providedIn: 'root'
})
export class Stategql extends Query<StateResponse> {
  document = gql`
  query GetState($country_id: Int_comparison_exp!) {
    res_country_state(where: {country_id: $country_id}, order_by: {name: asc}) {
      id
      name
    }
  }`;
}


// get all city by state id
export interface City {
  id: number;
  city_name: string;
}

export interface CityResponse {
  res_city: City[];
}
@Injectable({
  providedIn: 'root'
})
export class Citygql extends Query<CityResponse> {
  document = gql`
  query GetCity($state_id: Int_comparison_exp!) {
    res_city(where: {state_id: $state_id}, order_by: {city_name: asc}) {
      city_name
      id
    }
  }`;
}


//insert shipping address
export interface InsertShippingAddress {
  insert_res_partner: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class InsertShippingAddressgql extends Query<InsertShippingAddress> {
  document = gql`
  mutation InsertShippingAddress($name: String!, $street: String!, $street2: String!, $city: Int!, $state_id: Int!, $country_id: Int!, $email: String!, $mobile: String!, $zip: String!) {
    insert_res_partner(objects: {name: $name, street: $street, street2: $street2, city_id: $city, state_id: $state_id, country_id: $country_id, email: $email, mobile: $mobile, zip: $zip,active: true}) {
      returning {
        id
      }
      affected_rows
    }
  }`;
}

// update shipping address
export interface UpdateShippingAddress {
  update_partner_shipping_address: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class UpdateShippingAddressgql extends Query<UpdateShippingAddress> {
  document = gql`
  mutation UpdateShippingAddress($id: Int!, $name: String!, $street: String!, $street2: String, $city: Int!, $state_id: Int!, $country_id: Int!, $email: String!, $mobile: String!, $zip: String!) {
    update_partner_shipping_address(where: {id: {_eq: $id}}, _set: {name: $name, street: $street, street2: $street2, city_id: $city, state_id: $state_id, country_id: $country_id, email: $email, mobile: $mobile, zip: $zip}) {
      affected_rows
    }
  }`;
}


//delete shipping address
export interface DeleteShippingAddress {
  update_res_partner: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
// export class DeleteShippingAddressgql extends Query<DeleteShippingAddress> {
//   document = gql`
//   mutation DeleteShippingAddress($id: Int!) {
//     delete_res_partner(where: {id: {_eq: $id}}) {
//       affected_rows
//     }
//   }`;
// }
// delete shipping address mutation
export class DeleteShippingAddressgql extends Query<DeleteShippingAddress> {
  document = gql`
  mutation DeleteShippingAddress($id: Int!) {
    update_partner_shipping_address(where: {id: {_eq: $id}}, _set: {active: false}) {
      affected_rows
    }
  }`;
}


// get shipping address by id
export interface ShippingAddressById {
  id: number;
  name: string;
  street: string;
  street2: string;
  city: string;
  state_id: number;
  country_id: number;
  email: string;
  mobile: string;
  zip: string;
}

export interface ShippingAddressByIdResponse {
  partner_shipping_address: ShippingAddressById[];
}
@Injectable({
  providedIn: 'root'
})
export class ShippingAddressByIdgql extends Query<ShippingAddressByIdResponse> {
  document = gql`
  query GetShippingAddressById($id: Int_comparison_exp!) {
    partner_shipping_address(where: {id: $id}) {
      id
      name
      street
      street2
      city_id
      res_city{
        id
        city_name
      }
      state{
        id
        name
      }
      country{
        id
        name
      }
      email
      mobile
      zip
    }
  }`;
}




// get coupons
export interface Coupon {
  id: number;
  promo_code: string;
  name: string;
}


export interface CouponResponse {
  coupon_program: Coupon[];
}
@Injectable({
  providedIn: 'root'
})
export class Coupongql extends Query<CouponResponse> {
  document = gql`
  query allCoupons {
    coupon_program(where: {promo_code: {_is_null: false}}) {
      name
      rewardslist {
        id
        rewardproduct {
          producttemplate {
            name
          }
        }
      }
      promo_code
      id
    }

  }`;
}


// get user by id
export interface User {
  id: number;
  res_partner
  email: string;
  mobile: string;
  password: string;
}
export interface res_partner {
  name: string;
}


export interface UserResponse {
  res_users: User[];
}
@Injectable({
  providedIn: 'root'
})
export class Usergql extends Query<UserResponse> {
  document = gql`
  query GetUser {
    res_users {
      id
      login
      phone_number
    }
    res_partner {
      id
      name
    }
  }`;
}

// update user
export interface UpdateUser {
  update_res_users: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class UpdateUsergql extends Query<UpdateUser> {
  document = gql`
  mutation UpdateUser($name: String!) {
    update_res_partner(_set: {name: $name, display_name: $name}, where: {id: {_is_null: false}}) {
      returning {
        name
        display_name
      }
    }
  }
  `;
}


// change password
export interface ChangePassword {
  change_pass: {
    message: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class ChangePasswordgql extends Query<ChangePassword> {
  document = gql`
  mutation updatePassword($password: String!,$old_password: String!) {
    change_pass(password: $password, old_password: $old_password) {
      result {
        message
        status
      }
    }
  }
  `;
}

export interface Order {
  id: number;
}
// get orders
export interface Orders {
  sale_order: Order[];
}
@Injectable({
  providedIn: 'root'
})
export class Ordersgql extends Query<Orders> {
  document = gql`
 query getOrders($limit: Int!, $offset: Int!) {
  sale_order(order_by: {date_order: desc}, limit: $limit, offset: $offset) {
    id
    name
    amount_total
    create_date
    amount_tax
    date_order
    status
    Payment_status
    payment_date
    payment_mode
    partner_shipping_id
    sale_order_lines {
      product_id
      product_uom_qty
      product_uom
    }
    ir_attachment_sale_order_rels {
      ir_attachment_id
    }
    sale_order_lines_aggregate {
      aggregate {
        count
      }
    }
  }
}`;

}


//get wishlist
export interface Wishlist {
  product_id: number;
  wishlistproduct: Product;
}
export interface WishlistItems {
  wishlist_product_id: Wishlist[];
}
@Injectable({
  providedIn: 'root'
})
export class Wishlistgql extends Query<WishlistItems> {
  document = gql`
  query Wishlist {
    wishlist_product_id {
      product_id
      wishlistproduct {
        id
        name
        list_price
        full_price
        full_amount
        product_discount
        procured_by
        status_id
        product_products {
          id
        }
        wishlistproduct {
          id
        }
      }
    }
  }`;


}


// add to wishlist

export interface WishlistAdd {
  id: number;
}
@Injectable({
  providedIn: 'root'
})
export class WishlistAddgql extends Query<WishlistAdd> {
  document = gql`
  mutation AddWishList($product_id: Int!) {
    insert_wishlist_product_id_one(object: {product_id: $product_id}) {
      id
    }
  }`;

}


//remove from wishlist

export interface WishlistRemove {
  affected_rows: number;
}
@Injectable({
  providedIn: 'root'
})
export class WishlistRemovegql extends Query<WishlistRemove> {
  document = gql`
  mutation RemoveFromWishlist($product_id: Int!) {
    delete_wishlist_product_id(where: {product_id: {_eq: $product_id}}) {
      affected_rows
    }
  }`;

}



// get terms and conditions

export interface TermsAndConditions {
  tele_terms: string;
}
@Injectable({
  providedIn: 'root'
})
export class Termsgql extends Query<TermsAndConditions>{
  document = gql`{
    tele_terms {
      id
      terms
    }
  }`;

}

export interface Aboutus {
  about_master: string;
}
@Injectable({
  providedIn: 'root'
})
export class Aboutusgql extends Query<Aboutus>{
  document = gql`{
    about_master {
      id
      descrption
    }
  }`;

}

export interface Privacypolicy {
  about_master: string;
}
@Injectable({
  providedIn: 'root'
})
export class Privacypolicygql extends Query<Privacypolicy>{
  document = gql`{
    about_master {
      id
      descrption
    }
  }`;

}

// UploadPrescription
export interface UploadPrescription {
  Prescriptionupload: {
    message: string;
    id: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class UploadPrescriptiongql extends Query<UploadPrescription> {
  document = gql`
  mutation UploadPrescription($image: String!, $file_name: String!) {
    Prescriptionupload(image: $image, file_name: $file_name) {
      result {
        message
        id
      }
    }
  }`;

}

//Filtersgql
export interface Filters {
  product_category: Category[],
  brand_master: Brand[],
  symptoms_master: Symptom[],
}
@Injectable({
  providedIn: 'root'
})
export class Filtersgql extends Query<Filters> {
  document = gql`
  query GetFilters {
    product_category {
      id
      name
    }
    brand_master(where: {product_brand: {categ_id: {_eq: $_eq}}}) {
        name
        id
      }
    symptoms_master {
      id
      name
    }
  }`;

}

//support chat subscription
export interface SupportChat {
  admin_chat: {
    id: number;
    message: string;
    create_date: string;
    user_id: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class SupportChatgql extends Subscription<SupportChat> {
  document = gql`
  subscription admin_chat {
    admin_chat(order_by: {id: asc}) {
      author_id
      body
      email_from
      date
      res_id
    }
  }`;

}


// send support chat
export interface SendSupportChat {
  insert_mail_message: {
    message: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class SendSupportChatgql extends Query<SendSupportChat> {
  document = gql`
  mutation MyMutation ($body :String!,$date :timestamp,$email_from:String!,$mail_channel_id:Int!) {
    insert_mail_message(objects: {message_type: "comment",body: $body, mail_messages_channel: {data: {mail_message_mail_channel_rel: {data: {mail_channel_id: $mail_channel_id}}, message_type: "comment", model: "mail.channel", res_id: $mail_channel_id, subtype_id: 1,body:$body,date: $date,email_from:$email_from }}}) {
      returning {
        body
        id
      }
    }
  }
`;

}

//insert sale order
export interface InsertSaleOrder {
  createsaleorder: {
    saleorderid: number,
    status: String,
  }
}
@Injectable({
  providedIn: 'root'
})
export class InsertSaleOrdergql extends Query<InsertSaleOrder> {
  document = gql`
  mutation InsertSaleOrder($delivery_address: Int!, $datas: String!,$prescription_id: String!,$payment_mode: String!,$coupon_id: String!,$wallet_amount: String!) {
    createsaleorder(delivery_address: $delivery_address, datas: $datas, prescription_id: $prescription_id,payment_mode: $payment_mode,coupon_id: $coupon_id,wallet_amount: $wallet_amount) {
      result {
        saleorderid
        status
      }
    }
  }`;

}


//get prescription list
export interface PrescriptionList {
  prescription_upload: {
    ir_attachment_prescription_upload_rels
  }
}
@Injectable({
  providedIn: 'root'
})
export class PrescriptionListgql extends Query<PrescriptionList> {
  document = gql`
  query GetPrescription($limit: Int!, $offset: Int!,$user_id: Int!) {
    prescription_upload(where: {patient_resid: {_eq: $user_id}},limit: $limit, offset: $offset,order_by: {create_date: desc}) {
      create_date
      ir_attachment_prescription_upload_rels {
        ir_attachment_id
        ir_attachment {
          name
          mimetype
        }
      }
    }
  }
  `;

}


//get er prescription list
export interface erPrescriptionList {
  prescription_detail: {
    doctor_registration
  }
}
@Injectable({
  providedIn: 'root'
})
export class erPrescriptionListgql extends Query<erPrescriptionList> {
  document = gql`
  query GetEPrescription($limit: Int!, $offset: Int!,$partner_id: Int!) {
    prescription_detail(where: {res_patient: {_eq: $partner_id}},limit: $limit, offset: $offset,order_by: {create_date: desc}) {
      create_date
      id
      doctor_registration {
        first_name
        medical_speacialization {
          name
        }
      }

    }
  }
  `;

}


//get notification subscription
export interface Notification {
  tele_notification: {
    id: number;
    name: string;
    descrption: string;
    create_date: string;
    read_status: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class Notificationgql extends Subscription<Notification> {
  document = gql`
  subscription MySubscription {
    tele_notification(order_by: {create_date: desc}) {
      id
      name
      descrption
      appointment_id
      sale_id
      res_partner_id
      res_users
      create_date
      status_id
      read_status
    }
  }`;

}


// mark as read
export interface MarkAsRead {
  affected_rows: number;
}
@Injectable({
  providedIn: 'root'
})
export class MarkAsReadgql extends Query<MarkAsRead> {
  document = gql`
  mutation MyMutation($id: Int!) {
    update_tele_notification(where: {id: {_eq: $id}}, _set: {read_status: "read"}) {
      affected_rows
    }
  }`;

}


// get orders by id
export interface OrdersById {
  sale_order: {
    id: number;
    name: string;
    create_date: string;
    state: string;
  }
}

// amount_untaxed

@Injectable({
  providedIn: 'root'
})
export class OrdersByIdgql extends Query<OrdersById> {
  document = gql`
  query getOrdersById($_eq: Int!) {
    sale_order(where: {id: {_eq: $_eq}}) {
      id
      name
      amount_total
      create_date
      amount_tax
      date_order
      status
      payment_date
      payment_mode
      Payment_status
      item_total
      coupon_amount
      wallet_amount
      amount_due
      paid_amount
      state
      commitment_date
      ir_attachment_sale_order_rels {
        ir_attachment_id
      }
      sale_order_lines_aggregate {
        aggregate {
          count
        }
      }
      sale_order_lines {
        id
        name
        price_subtotal
        price_tax
        price_total
        price_unit
        product_uom
        product_uom_qty
        product_id
        product_product {
          product_template {
            expiration_date
            id
          }
        }
      }
      partner_shipping_id
      sale_order_logs {
        id
        log_date
        order_status
        order_status_log
      }
      shippingaddress {
        country {
          name
        }
        email
        id
        mobile
        name
        state {
          name
        }
        street
        street2
        zip
      }
    }
  }
  `;

}



// order with prescription mutation
export interface OrderWithPrescription {
  createsaleorder: {
    status: String,
    saleorderid: number
  }
}
@Injectable({
  providedIn: 'root'
})
export class OrderWithPrescriptiongql extends Query<OrderWithPrescription> {
  document = gql`
  mutation PrescriptionOrder($prescription_id: String!, $delivery_address: String!, $comments: String!,$as_per_prescription: String!) {
    sale_prescription(comments: $comments, delivery_address: $delivery_address, prescription_id: $prescription_id,as_per_prescription: $as_per_prescription) {
      result {
        saleorderid
        status
      }
    }
  }`;

}


// confirm order mutation
export interface ConfirmOrder {
  confirmorder: {
    message: String
  }
}
@Injectable({
  providedIn: 'root'
})
export class ConfirmOrdergql extends Query<ConfirmOrder> {
  document = gql`
  mutation UpdateStatus($ref_key: String!, $id: Int!) {
    sale_key(id: $id, ref_key: $ref_key) {
      result {
        message
      }
    }
  }
  `;

}

//UpdateProfilePicgql
export interface UpdateProfilePic {
  prof_image: {
    status: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class UpdateProfilePicgql extends Query<UpdateProfilePic> {
  document = gql`
  mutation UpdateProfileImage($pro_image: String!) {
    prof_image(pro_image: $pro_image) {
      result {
        status
      }
    }
  }`;

}


//get profile pic
export interface ProfilePic {
  tele_user: {
    image: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class ProfilePicgql extends Query<ProfilePic> {
  document = gql`
  query GetProfilePic($id: Int!) {
    tele_user(where: {id: {_eq: $id}}) {
      image
    }
  }`;

}

// help and support
export interface HelpAndSupport {
  help_support: {
    helps: string;
    id: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class HelpAndSupportgql extends Query<HelpAndSupport> {
  document = gql`
  query HelpSupport {
    help_support {
      helps
      id
    }
  }`;

}

// help and support lines by id
export interface HelpAndSupportLinesById {
  help_support: {
    helps: string;
    id: number;
    help_support_lines;
  }
}
@Injectable({
  providedIn: 'root'
})
export class HelpAndSupportLinesByIdgql extends Query<HelpAndSupportLinesById> {
  document = gql`
  query HelpSupportByid($_eq: Int = 10) {
    help_support(where: {id: {_eq: $_eq}}) {
      helps
      help_support_lines {
        answer
        id
        question
      }
      id
    }
  }`;

}


// track order
export interface TrackOrder {
  sale_order_log: {
    order_status_log: string;
    log_date: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class TrackOrdergql extends Query<TrackOrder> {
  document = gql`
  query TrackOrder($_eq: Int!) {
    sale_order_log(where: {sale_order_id: {_eq: $_eq}}) {
      sale_order_id
      log_date
      order_status_log
    }
  }
  `;

}



//add to cart
export interface AddToCart {
  insert_add_cart: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class AddToCartgql extends Query<AddToCart> {
  document = gql`
  mutation AddToCart($products: Int, $quantity: float8,$save_later_quantity: float8) {
    insert_add_cart(objects: {products: $products, quantity: $quantity,save_later_quantity:$save_later_quantity}) {
      affected_rows
    }
  }
  `;

}


//get cart
export interface GetCart {
  add_cart: {
    quantity: number;
    product: Product;
  }
}
@Injectable({
  providedIn: 'root'
})
export class GetCartgql extends Query<GetCart> {
  document = gql`
  query GetCart {
    add_cart {
      id
      quantity
      saveforlater
      product {
        id
        name
        list_price
        full_price
        website_description
        manufacturer_name
        package_type
        procured_by
        page_descri
        net_qty
        ingredient
        country_of_origin
        directions_to_use
        item_weight
        hsn_code
        uom_id
        product_products {
          id
        }
        sideeffect_formdescripitions
        therapeutic_descripitions
        warning_formdescripitions
        moreinfo_formdescripitions
        interactions_formdescripitions
        direction_formdescripitions
        images {
          id
        }
        wishlistproduct {
          id
        }
      }
      }
    }
  `;

}


//update cart quantity
export interface UpdateCartQuantity {
  update_add_cart: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class UpdateCartQuantitygql extends Query<UpdateCartQuantity> {
  document = gql`
  mutation UpdateCart($quantity: float8!, $product_id: Int!) {
    update_add_cart(where: {products: {_eq: $product_id}}, _set: {quantity: $quantity,saveforlater: false}) {
      affected_rows
    }
  }
  `;

}


//delete cart item by product id
export interface DeleteCartItemByProductId {
  delete_add_cart: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class DeleteCartItemByProductIdgql extends Query<DeleteCartItemByProductId> {
  document = gql`
  mutation DeleteCartItem($product_id: Int!) {
    delete_add_cart(where: {products: {_eq: $product_id}}) {
      affected_rows
    }
  }
  `;

}


//empty cart
export interface EmptyCart {
  delete_add_cart: {
    affected_rows: number;
  }
}
@Injectable({
  providedIn: 'root'
})
export class EmptyCartgql extends Query<EmptyCart> {
  document = gql`
  mutation EmptyCart {
    delete_add_cart {
      affected_rows
    }
  }
  `;

}



@Injectable({
  providedIn: 'root'
})
export class GetAllOffersgql extends Query<any> {
  document = gql`
  query GetAllOffers {
    offer_category {
      name
      id
    }
    market_offers(order_by: {id: desc}) {
    coupan
    date
    description
    id
    name
    conditions
    elgibility
  }
  }
  `;

}


//get all offers by category id
@Injectable({
  providedIn: 'root'
})
export class GetAllOffersByCategorygql extends Query<any> {
  document = gql`
  query GetOffers($category_id: Int!) {
    market_offers(where: {offer_category: {_eq: $category_id}}, order_by: {id: desc}) {
      coupan
      date
      description
      id
      name
      conditions
      elgibility

    }
  }
  `;

}

//get offer by pk
@Injectable({
  providedIn: 'root'
})
export class GetOfferByPk extends Query<any> {
  document = gql`
  query GetOfferByPk($id: Int!) {
    market_offers_by_pk(id: $id) {
      id
      coupan
      description
      date
      name
      conditions
      elgibility
      coupon_program {
        promo_code
    }
    }
  }
  `;
}

@Injectable({
  providedIn: 'root'
})
export class WalletHistory extends Query<any> {
  document = gql`
  query WalletHistories{
    account_wallet {
      name
      id
      account_move_lines (order_by: {id: desc}) {
        id
        credit
        debit
        move_id
        name
        create_date
      }
    }
  }`;
}

@Injectable({
  providedIn: 'root'
})
export class WalletAmount extends Query<any> {
  document = gql`
  query Coins{
    account_wallet {
      balance
      id
      name
    }
  }`;
}

export interface CouponResponse {
  result: {
    message: string;
    active_count: string;
  }
}

@Injectable({
  providedIn: 'root'
})

export class GetAllCoupons extends Query<CouponResponse> {
  document = gql`
  mutation MyMutation($partner_id :Int!) {
    coupon_promo(partner_id: $partner_id) {
      result {
        active_count
        message
      }
    }
  }`;
}



// Forgot password:

export interface forgotpassword {
  result: {
    message: string;
    status: string;
  }
}
@Injectable({
  providedIn: 'root'
})

export class ForgotPasswordgql extends Query<forgotpassword> {
  document = gql`
  mutation forgotpassword($email: String!) {
    forgot_paasword(email: $email) {
      result {
        message
        status
      }
    }
  }`;
}

//one signal create

// export interface oneSiganlCreate{
//   returning:{
//     id: number;
//     key: string;
//   }
// }
// @Injectable({
//   providedIn: 'root'
// })

// export class oneSiganlgql extends Query<oneSiganlCreate> {
//   document = gql`
//   mutation oneSignalInsert($id: number!,$platform: String!) {
//     insert_one_signal_master(objects: {key: $id,signal_type: $platform}) {
//       returning {
//         id
//         key
//       }
//     }
//   }`;
// }







