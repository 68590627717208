import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(image: string,model:string,field:string): string {
    let random = Math.floor(Math.random() * 100);
    // console.log('WEBURL',"https://telehealth.mo.vc/web/image?model="+model+"&id="+image+"&field="+field+"&unique="+random);
    return "https://telehealth.mo.vc/web/image?model="+model+"&id="+image+"&field="+field+"&unique="+random;

  }

}
