import { Component, ViewChildren,QueryList } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { Router } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Subject,Subscription } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { Platform, ModalController,AlertController, ActionSheetController, PopoverController, IonRouterOutlet, MenuController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import { AccountService } from '@services/account.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userdata : any;
  isauthenticated: boolean = false;
  profilepic:any;
  res_data:any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList < IonRouterOutlet > ;

  connectSubscription:Subscription;
  disconnectSubscription:Subscription;


  Pages = [
    {
      title: 'Home',
      url: 'tabs/home',
      src: 'assets/images/sidemenu/home.svg'
    },
    {
      title: 'My Wallet & Coupons',
      url: 'wallet',
      src: 'assets/images/sidemenu/wallet.svg'
    },
    {
      title: 'My orders',
      url: 'orders',
      src: 'assets/images/sidemenu/orders.svg'
    },
    {
      title: 'My Prescriptions',
      url: 'prescriptions',
      src: 'assets/images/sidemenu/prescription.svg'
    },
    {
      title: 'Wishlist',
      url: 'wishlist',
      src: 'assets/images/sidemenu/heart.svg'
    },
    {
      title: 'Address',
      url: 'addresses',
      src: 'assets/images/sidemenu/address.svg'
    },
    {
      title: 'Notifications',
      url: 'notification',
      src: 'assets/images/sidemenu/notification.svg'
    },
    {
      title: 'Help and support',
      url: 'support',
      src: 'assets/images/sidemenu/support.svg'
    },
    {
      title: 'Privacy Policy',
      url: 'privacypolicy',
      src: 'assets/images/sidemenu/policy.svg'
    },
    {
      title: 'Terms and conditions',
      url: 'terms',
      src: 'assets/images/sidemenu/terms.svg'
    },
    {
      title: 'About Us',
      url: 'aboutus',
      src: 'assets/images/sidemenu/terms.svg'
    },
    {
      title: 'Offers',
      url: 'offers',
      src: 'assets/images/sidemenu/offers.svg'
    }

  ];

  constructor(private auth:AuthenticationService,private router: Router,private network: Network,private platform: Platform,public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    private menu: MenuController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private notificationService: NotificationService,
    private navCtrl: NavController,
    private screenOrientation: ScreenOrientation,
    private accountService: AccountService,
    ) {
    console.log("App component constructor");
    this.auth.isAuthenticated.subscribe(res => {
      this.isauthenticated = res;
    });

    this.auth.userdata.subscribe(data => {
     this.userdata = data;

      if(this.userdata != null){
     this.profilepic=this.auth.formatProfilePic(data.partner_id);

      }

    });
    platform.ready().then(()=>{
      this.setuppush();
    })
    this.initializeApp();
    this.getuserid()
    console.log(this.router.url)

  }

  async getuserid(){
    this.accountService.getUser().subscribe((res:any) => {
      this.res_data=res.res_users[0]
    })
  }
  async setuppush()
  {
  // this.onesignal.startInit('a6d84c41-db41-4dbc-8e69-8a059d1f738f','340199672772 ');
  OneSignal.setAppId("a6d84c41-db41-4dbc-8e69-8a059d1f738f");
  var that=this
  OneSignal.setNotificationOpenedHandler(function(jsonData) {
    console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    let add_data=jsonData.notification.additionalData
        console.log('json',add_data['type'])
        if (add_data['type'] == 'order_detail'){
          that.router.navigate(['orders/details/'+add_data['order_id']]);
        }
        else if (add_data['type'] == 'support'){
          that.router.navigate(['/tabs/chat']);
        }

  });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      this.backButtonEvent();
      console.log("App initialized");
      this.network_diconnection();

    });
    }

    async LogoutConfirmation() {
      this.getuserid()
      console.log('uid',this.res_data)
      const alert = await this.alertCtrl.create({
        // header: 'Confirm!',
        message: 'Are you want to Sign out the app?',
        cssClass: 'mess-age-nm',
        buttons: [{
          text: 'No',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Yes',
          handler: () => {
            let keyid=localStorage.getItem("onesignalid")
            var that=this
            console.log('f',keyid,this.res_data?.id)
            this.auth.DeleteOneSignalId(keyid,this.res_data?.id).subscribe((result: any) => {
              console.log('check',result);
              if (result){
                that.auth.logout();
                localStorage.removeItem('onesignalid')
                that.router.navigate(['/otp-login']);
              }
            },(err) =>{
              that.auth.logout();
              localStorage.removeItem('onesignalid')
              that.router.navigate(['/otp-login']);
            });
            // redirect to home
            // this.router.navigate(['/register']);
            // that.router.navigate(['/otp-login']);
          }
        }]
      });
     await alert.present();
    }
  logout() {
    console.log('res',this.res_data)
    let keyid=localStorage.getItem("onesignalid")
    var that=this
    console.log('f',keyid,this.res_data?.id)
    this.auth.DeleteOneSignalId(keyid,this.res_data?.id).subscribe((result: any) => {
      console.log('check',result);
      if (result){
        that.auth.logout();
        localStorage.removeItem('onesignalid')
      }
    },(err) =>{
      that.auth.logout();
      localStorage.removeItem('onesignalid')
    });
   this.LogoutConfirmation();
  }


  login() {
    this.router.navigate(['/otp-login']);
  }

  editprofile() {
    // close the menu
    this.router.navigate(['/edit-profile']);
  }

  network_diconnection(){
    // watch network for a disconnection
  this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
    console.log('network was disconnected :-(');
    //message with image
    let message="Please check your internet connection"+"<img src='assets/images/nointernet.jpg'/>";
    this.createAlert('Network Disconnected', message ).then(alert => {
      alert.present();
    });

    this.network_connection();
  });

}

network_connection(){
  this.unsubscribe_disconect();
  // watch network for a connection
this.connectSubscription = this.network.onConnect().subscribe(() => {
  this.network_diconnection();
  console.log('network connected!');
  this.alertCtrl.dismiss();
  this.unsubscribe_connect();
setTimeout(() => {
    if (this.network.type === 'wifi') {
      this.network_diconnection();
      console.log('we got a wifi connection, woohoo!');
      this.unsubscribe_connect();
    }
  }, 3000);
});

}

unsubscribe_disconect(){
// stop disconnect watch
this.disconnectSubscription.unsubscribe();

}
unsubscribe_connect(){
// stop connect watch
this.connectSubscription.unsubscribe();
}


async createAlert(header, message): Promise<HTMLIonAlertElement> {
  const alert = await this.alertCtrl.create({
  header,
  cssClass: 'nointernet',
  backdropDismiss: false,
  message
  });
  return alert;
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      this.routerOutlets.forEach(async(outlet: IonRouterOutlet) => {
        if (this.router.url != '/tabs/home') {
          if(outlet && !outlet.canGoBack()){
          await this.navCtrl.navigateRoot('/tabs/home');

          }else{
            // back
            outlet.pop();
          }
        } else if (this.router.url === '/tabs/home') {
          if (new Date().getTime() - this.lastTimeBackPress >= this.timePeriodToExit) {
            this.lastTimeBackPress = new Date().getTime();
            this.presentAlertConfirm();
          } else {
            navigator['app'].exitApp();
          }
        }
      });
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      // header: 'Confirm!',
      message: 'Are you sure you want to exit the app?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {}
      }, {
        text: 'Close App',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });

    await alert.present();
  }



}
